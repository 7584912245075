import { api } from "@/api-client";

class WorkTypeTranslator {
    translate(workType: api.WorkType): string {
        switch (workType) {
            case api.WorkType.LineWork:
                return "Liinitöö";
            case api.WorkType.OrderedTransit:
                return "Tellimusvedu";
            case api.WorkType.InternalTransit:
                return "Sisevedu";
            case api.WorkType.SocialTransit:
                return "Sotsiaaltransport";
            case api.WorkType.SocialTransitReserve:
                return "Sotsiaaltransport - reserv";
            case api.WorkType.SocialTransitReserveOnLine:
                return "Sotsiaaltransport - reserv liinitööl";
            case api.WorkType.Trial:
                return "Proovisõit";
            case api.WorkType.Repair:
                return "Hooldus";
            case api.WorkType.Reserve:
                return "Reservis";
            case api.WorkType.LineWorkReserve:
                return "Reserv liinitööl";
            case api.WorkType.Wash:
                return "Pesu";
            case api.WorkType.Internship:
                return "Praktika";
        }
    }

    all = {
        Liinitöö: api.WorkType.LineWork,
        Tellimusvedu: api.WorkType.OrderedTransit,
        Sisevedu: api.WorkType.InternalTransit,
        Sotsiaaltransport: api.WorkType.SocialTransit,
        "Sotsiaaltransport - reserv": api.WorkType.SocialTransitReserve,
        "Sotsiaaltransport - reserv liinitööl": api.WorkType.SocialTransitReserveOnLine,
        Proovisõit: api.WorkType.Trial,
        Hooldus: api.WorkType.Repair,
        Reservis: api.WorkType.Reserve,
        "Reserv liinitööl": api.WorkType.LineWorkReserve,
        Pesu: api.WorkType.Wash,
        Praktika: api.WorkType.Internship,
    };
}

const workTypeTranslator = new WorkTypeTranslator();
export default workTypeTranslator;
