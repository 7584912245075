import workTypeTranslator from "@/features/WorkTypeTranslator";
import { formatTime } from "@/helpers/DateFormatter";
import { api } from "@/api-client";
import { ListFilters } from "@/components/ListFilters";
import { IMonitoredJobAndVehicleLocationSnapshotModel } from "@/features/jobsMonitoring/MonitoredJobAndVehicleLocationSnapshotModel";
import { RouteLocationNormalized } from "vue-router";
import { getNonEmpty } from "@/helpers/Utils";

export abstract class JobsMonitoringCommonFilters extends ListFilters {
    static readonly NO_VEHICLE = "Sõiduk puudub";
    static readonly NO_LOCATION = "Tundmatu asukoht";

    searchString: string | undefined = undefined;
    lineNumbers: string[] = [];
    vehicleTypes: string[] = [];
    locations: string[] = [];
    workTypes: string[] = [];
    departments: string[] = [];
    driverGroups: string[] = [];
    plannedDepartureTimeFrom: string | undefined;
    plannedDepartureTimeTo: string | undefined;

    createData() {
        return {
            searchString: this.searchString?.length ? this.searchString : undefined,
            lineNumbers: this.lineNumbers?.length ? this.lineNumbers : [],
            vehicleTypes: this.vehicleTypes?.length ? this.vehicleTypes : [],
            locations: this.locations?.length ? this.locations : [],
            workTypes: this.workTypes?.length ? this.workTypes : [],
            departments: this.departments?.length ? this.departments : [],
            driverGroups: this.driverGroups?.length ? this.driverGroups : [],
            plannedDepartureTimeFrom: this.plannedDepartureTimeFrom ? this.plannedDepartureTimeFrom : undefined,
            plannedDepartureTimeTo: this.plannedDepartureTimeTo ? this.plannedDepartureTimeTo : undefined,
        };
    }

    mapFromObject(data: any) {
        this.searchString = data.searchString;
        this.lineNumbers = data.lineNumbers;
        this.vehicleTypes = data.vehicleTypes;
        this.locations = data.locations;
        this.workTypes = data.workTypes;
        this.departments = data.departments;
        this.driverGroups = data.driverGroups;
        this.plannedDepartureTimeFrom = data.plannedDepartureTimeFrom;
        this.plannedDepartureTimeTo = data.plannedDepartureTimeTo;
    }

    mapFromRoute(route: RouteLocationNormalized) {
        this.searchString = route.query.search ? String(route.query.search) : undefined;
        this.lineNumbers = route.query.lineNumbers ? String(route.query.lineNumbers).split(",") : [];
        this.vehicleTypes = route.query.vehicleTypes ? String(route.query.vehicleTypes).split(",") : [];
        this.locations = route.query.locations ? String(route.query.locations).split(",") : [];
        this.workTypes = route.query.workTypes ? String(route.query.workTypes).split(",") : [];
        this.departments = route.query.departments ? String(route.query.departments).split(",") : [];
        this.driverGroups = route.query.driverGroups ? String(route.query.driverGroups).split(",") : [];
        this.plannedDepartureTimeFrom = route.query.plannedDepartureTimeFrom ? (route.query.plannedDepartureTimeFrom as string) : undefined;
        this.plannedDepartureTimeTo = route.query.plannedDepartureTimeTo ? (route.query.plannedDepartureTimeTo as string) : undefined;
    }

    clearAllFilters() {
        this.lineNumbers = [];
        this.vehicleTypes = [];
        this.locations = [];
        this.workTypes = [];
        this.departments = [];
        this.driverGroups = [];
        this.plannedDepartureTimeFrom = undefined;
        this.plannedDepartureTimeTo = undefined;
    }

    applyCommonFiltersOn(viewModel: api.IMonitoredJobViewModel): boolean {
        if (this.lineNumbers.length && !this.lineNumbers.some((lineNumber) => viewModel.job.lineNumbers.includes(lineNumber))) {
            return false;
        }

        if (this.vehicleTypes.length && !this.vehicleTypes.includes(viewModel.vehicle?.type.label() ?? JobsMonitoringCommonFilters.NO_VEHICLE)) {
            return false;
        }

        if (this.workTypes.length && !this.workTypes.includes(workTypeTranslator.translate(viewModel.job.workType))) {
            return false;
        }

        if (this.departments.length && !this.departments.includes(viewModel.driver.department)) {
            return false;
        }

        if (this.driverGroups.length && !this.driverGroups.includes(viewModel.driver.group)) {
            return false;
        }

        if (this.plannedDepartureTimeFrom && viewModel.job.plannedDepartureTime && formatTime(viewModel.job.plannedDepartureTime) < this.plannedDepartureTimeFrom) {
            return false;
        }

        if (this.plannedDepartureTimeTo && viewModel.job.plannedDepartureTime && formatTime(viewModel.job.plannedDepartureTime) >= this.plannedDepartureTimeTo) {
            return false;
        }

        if (
            this.searchString?.length &&
            !viewModel.driver.name.toLowerCase().includes(this.searchString.toLowerCase()) &&
            !viewModel.driver.tableNumber.toLowerCase().includes(this.searchString.toLowerCase()) &&
            !viewModel.vehicle?.garageNumber?.toLowerCase().includes(this.searchString.toLowerCase())
        ) {
            return false;
        }

        return true;
    }

    abstract saveToSessionStorage(): void;
}
