import script from "./CreateSendLineToReserveModal.vue?vue&type=script&lang=ts&setup=true"
export * from "./CreateSendLineToReserveModal.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QField from 'quasar/src/components/field/QField.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabPanel,QField,QRadio});
