import { api } from "@/api-client";

export class OperationModalTab {
    index: number;
    isValid: boolean;
    validateInner: (incidentId: number, inputModel: any, resultError: api.ResultError) => boolean | Promise<boolean>;

    constructor(index: number, validate: (incidentId: number, inputModel: any, resultError: api.ResultError) => boolean | Promise<boolean>) {
        this.index = index;
        this.isValid = false;
        this.validateInner = validate;
    }

    async validate(incidentId: number, inputModel: any, resultError: api.ResultError): Promise<boolean> {
        this.isValid = await this.validateInner(incidentId, inputModel, resultError);
        return this.isValid;
    }
}

export interface IOperationModalTabs {
    tabs: OperationModalTab[];
    currentTabIndex: number;
    isCurrent(tab: OperationModalTab): boolean;
    isValid(index: number): boolean;
    canBeNavigatedTo(index: number): boolean;
    next(incidentId: number, inputModel: any, resultError: api.ResultError): Promise<void>;
    previous(): void;
}

export default abstract class OperationModalTabs implements IOperationModalTabs {
    tabs: OperationModalTab[];
    currentTabIndex: number;

    protected constructor(tabs: OperationModalTab[], currentTabIndex: number) {
        this.tabs = tabs;
        this.currentTabIndex = currentTabIndex;
    }

    private get minIndex() {
        return this.tabs.map((tab) => tab.index).reduce((a, b) => Math.min(a, b));
    }
    private get maxIndex() {
        return this.tabs.map((tab) => tab.index).reduce((a, b) => Math.max(a, b));
    }
    private nextIndex(index: number) {
        return Math.min(index + 1, this.maxIndex);
    }
    private previousIndex(index: number) {
        return Math.max(index - 1, this.minIndex);
    }

    isCurrent(tab: OperationModalTab) {
        return this.currentTabIndex == tab.index;
    }

    isValid(index: number) {
        return this.tabs.find((x) => x.index == index)?.isValid ?? false;
    }

    canBeNavigatedTo(index: number) {
        const previousTab = this.tabs.find((x) => x.index == this.previousIndex(index));
        return index == previousTab?.index || (previousTab?.isValid ?? false);
    }

    private resetValidation() {
        this.tabs.forEach((tab) => {
            tab.isValid = false;
        });
    }

    async validateUntil(index: number, incidentId: number, inputModel: any, resultError: api.ResultError) {
        this.resetValidation();
        for (let i = this.minIndex; i < index; i = this.nextIndex(i)) {
            const tab = this.getTab(i);
            await tab.validate(incidentId, inputModel, resultError);
        }
    }

    async next(incidentId: number, inputModel: any, resultError: api.ResultError) {
        const nextIndex = this.nextIndex(this.currentTabIndex);
        await this.validateUntil(this.nextIndex(this.currentTabIndex), incidentId, inputModel, resultError);

        if (this.canBeNavigatedTo(nextIndex)) {
            this.currentTabIndex = nextIndex;
        }
    }

    previous() {
        this.currentTabIndex = this.previousIndex(this.currentTabIndex);
    }

    protected getTab(index: number): OperationModalTab {
        const tab = this.tabs.find((x) => x.index === index);
        if (tab == null) {
            throw new Error(`Tab with index not found ${index}`);
        }

        return tab;
    }
}
