import { api } from "@/api-client";
import OperationModalTabs, { OperationModalTab } from "@/features/incidents/incidentView/shared/OperationModalTabs";

export default class ReturnDriverToLineOperationTabs extends OperationModalTabs {
    static JOB_AND_DRIVER_INDEX = 0;
    get JOB_AND_DRIVER(): OperationModalTab {
        return this.getTab(ReturnDriverToLineOperationTabs.JOB_AND_DRIVER_INDEX);
    }

    static VEHICLE_INDEX = 1;
    get VEHICLE(): OperationModalTab {
        return this.getTab(ReturnDriverToLineOperationTabs.VEHICLE_INDEX);
    }

    static STARTING_POINT_INDEX = 2;
    get STARTING_POINT(): OperationModalTab {
        return this.getTab(ReturnDriverToLineOperationTabs.STARTING_POINT_INDEX);
    }

    static PREPARATION_START_INDEX = 3;
    get PREPARATION_START(): OperationModalTab {
        return this.getTab(ReturnDriverToLineOperationTabs.PREPARATION_START_INDEX);
    }

    static REDIRECTIONS_INDEX = 4;
    get REDIRECTIONS(): OperationModalTab {
        return this.getTab(ReturnDriverToLineOperationTabs.REDIRECTIONS_INDEX);
    }

    static OVERVIEW_INDEX = 5;
    get OVERVIEW(): OperationModalTab {
        return this.getTab(ReturnDriverToLineOperationTabs.OVERVIEW_INDEX);
    }

    constructor(tabIndexes: number[], currentTabIndex: number) {
        const jobAndDriverTab = new OperationModalTab(ReturnDriverToLineOperationTabs.JOB_AND_DRIVER_INDEX, (_: number, model: api.ReturnDriverToLineInputModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            if (model.jobAndDriver.jobId == null) {
                validationErrors.appendMessage("Töö on kohustuslik", "jobId");
            }
            if (model.jobAndDriver.driverId == null) {
                validationErrors.appendMessage("Juht on kohustuslik", "driverId");
            }

            return !validationErrors.hasMessages();
        });

        const vehicleTab = new OperationModalTab(ReturnDriverToLineOperationTabs.VEHICLE_INDEX, (_: number, model: api.ReturnDriverToLineInputModel, validationErrors: api.ResultError) => {
            validationErrors.clear();
            if (model.vehicle.id == null) {
                validationErrors.appendMessage("Sõiduk on kohustuslik", "vehicleId");
            }
            return !validationErrors.hasMessages();
        });

        const startingPointTab = new OperationModalTab(
            ReturnDriverToLineOperationTabs.STARTING_POINT_INDEX,
            (_: number, model: api.ReturnDriverToLineInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();

                if (model.driverStartingPoint == null) {
                    validationErrors.appendMessage("Alguspunkt on kohustuslik", "driverStartingPoint");
                }

                model.driverStartingPoint.validate(validationErrors);
                return !validationErrors.hasMessages();
            }
        );

        const preparationStartTab = new OperationModalTab(ReturnDriverToLineOperationTabs.PREPARATION_START_INDEX, () => true);

        const redirectionsTab = new OperationModalTab(
            ReturnDriverToLineOperationTabs.REDIRECTIONS_INDEX,
            async (incidentId: number, model: api.ReturnDriverToLineInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();
                await api.IncidentOperationRedirectionInputModel.validate(incidentId, model.redirections, validationErrors);
                return !validationErrors.hasMessages();
            }
        );

        const overviewTab = new OperationModalTab(ReturnDriverToLineOperationTabs.OVERVIEW_INDEX, () => true);

        super(
            [jobAndDriverTab, vehicleTab, startingPointTab, preparationStartTab, redirectionsTab, overviewTab].filter((x) => tabIndexes.some((i) => i == x.index)),
            currentTabIndex
        );
    }
}
