import { api } from "@/api-client";

class VehicleCategoryTranslator {
    translate(category: api.VehicleCategory) {
        switch (category) {
            case api.VehicleCategory.Bus:
                return "Buss";
            case api.VehicleCategory.Tram:
                return "Tramm";
            case api.VehicleCategory.Trolleybus:
                return "Troll";
            case api.VehicleCategory.Truck:
                return "Veoauto";
            case api.VehicleCategory.Minibus:
                return "Väikebuss";
            case api.VehicleCategory.Car:
                return "Sõiduauto";
            case api.VehicleCategory.Tractor:
                return "Traktor";
            case api.VehicleCategory.MobileMachinery:
                return "Liikurmasin";
            case api.VehicleCategory.Trailer:
                return "Haagis";
            case api.VehicleCategory.ForkLift:
                return "Kahveltõstuk";
            case api.VehicleCategory.SocialTransit:
                return "Sotsiaaltransport";
            case api.VehicleCategory.Unknown:
                return "Tundmatu";
            default:
                category satisfies never;
        }
    }

    stringToVehicleCategory(stringCategory: string): api.VehicleCategory {
        switch (stringCategory) {
            case "Buss":
                return api.VehicleCategory.Bus;
            case "Tramm":
                return api.VehicleCategory.Tram;
            case "Troll":
                return api.VehicleCategory.Trolleybus;
            case "Veoauto":
                return api.VehicleCategory.Truck;
            case "Väikebuss":
                return api.VehicleCategory.Minibus;
            case "Sõiduauto":
                return api.VehicleCategory.Car;
            case "Traktor":
                return api.VehicleCategory.Tractor;
            case "Liikurmasin":
                return api.VehicleCategory.MobileMachinery;
            case "Haagis":
                return api.VehicleCategory.Trailer;
            case "Kahveltõstuk":
                return api.VehicleCategory.ForkLift;
            case "Sotsiaaltransport":
                return api.VehicleCategory.SocialTransit;
            case "Tundmatu":
                return api.VehicleCategory.Unknown;
            default:
                return api.VehicleCategory.Unknown;
        }
    }
}

const vehicleCategoryTranslator = new VehicleCategoryTranslator();
export default vehicleCategoryTranslator;
