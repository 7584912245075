import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";

export class NotifyOfDriverAndVehicleChangeHubMessage extends HubMessage<NotifyOfDriverAndVehicleChangeHubMessage> {
    public override overrideFromJSON(data: any): NotifyOfDriverAndVehicleChangeHubMessage {
        return new NotifyOfDriverAndVehicleChangeHubMessage();
    }
}

export type OnNotifyOfDriverAndVehicleChangeNotification = (message: NotifyOfDriverAndVehicleChangeHubMessage) => void;

export class ArrivalsToTerminalHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("arrivals-to-terminal-hub");
    }

    public async open(onNotifyOfDriverAndVehicleChange: OnNotifyOfDriverAndVehicleChangeNotification) {
        await super.onMessage(NotifyOfDriverAndVehicleChangeHubMessage, "NotifyOfDriverAndVehicleChange", onNotifyOfDriverAndVehicleChange);
        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }

    public async getCurrentState() {
        await super.send("OnRequestCurrentState");
    }
}
