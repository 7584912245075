import { ListFilters } from "@/components/ListFilters";
import { api } from "@/api-client";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";

export interface IPendingJobsFilters {
    workTypes: string[];
}

export class PendingJobsFilters extends ListFilters implements IPendingJobsFilters {
    workTypes: string[] = [];
    vehicleTypes: string[] = [];

    static NoVehicleType = "Sõidukiliik puudub";

    mapFromRoute(route: RouteLocationNormalized) {
        this.workTypes = route.query.workTypes ? String(route.query.workTypes).split(",") : [];
        this.vehicleTypes = route.query.vehicleTypes ? String(route.query.vehicleTypes).split(",") : [];
    }

    async reflectToRoute(router: typeof Router) {
        await router.replace({ query: this.createQuery() });
    }

    private createQuery() {
        return {
            workTypes: this.workTypes.length ? this.workTypes : undefined,
            vehicleTypes: this.vehicleTypes.length ? this.vehicleTypes : undefined,
        };
    }
    clearAllFilters(): PendingJobsFilters {
        this.workTypes = [];
        this.vehicleTypes = [];
        return this;
    }

    applyOn(jobs: api.IPendingJobViewModel[]): api.IPendingJobViewModel[] {
        jobs = this.filterByWorkType(jobs);
        jobs = this.filterByVehicleType(jobs);
        return jobs;
    }
    filterByWorkType(jobs: api.IPendingJobViewModel[]) {
        return jobs.filter((job) => (this.workTypes.length ? this.workTypes.includes(workTypeTranslator.translate(job.workType)) : true));
    }
    filterByVehicleType(jobs: api.IPendingJobViewModel[]) {
        return jobs.filter((job) => (this.vehicleTypes.length ? this.vehicleTypes.includes(job.vehicle?.type.label() ?? PendingJobsFilters.NoVehicleType) : true));
    }
}
