import { RouteLocationNormalized } from "vue-router";
import Router from "@/router";
import { api } from "@/api-client";
import ReserveVehicleViewModel = api.ReserveVehicleViewModel;
import { ReserveWorkTypeFilter } from "@/features/reserves/ReserveWorkTypeFilter";
import { hasValue } from "@/helpers/Utils";
import { formatTime } from "@/helpers/DateFormatter";
import moment from "moment-timezone";

export class ReserveVehiclesListFilters extends ReserveWorkTypeFilter {
    searchString: string | undefined = undefined;
    vehicleTypes: string[] = [];
    marks: string[] = [];
    models: string[] = [];
    divisions: string[] = [];
    technicalConditions: string[] = [];
    vehicleFreeTimeFrom: string | undefined;
    vehicleFreeTimeTo: string | undefined;

    static readonly NO_VEHICLE = "Sõiduk puudub";
    static readonly NO_DIVISION = "Osakond puudub";
    static readonly NO_MARK = "Mark puudub";
    static readonly NO_MODEL = "Mudel puudub";

    readonly STORAGE_KEY = "ReserveVehiclesListFilters";

    map(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const storage = sessionStorage.getItem(this.STORAGE_KEY);

        if (hasRoute) {
            this.searchString = route.query.search ? String(route.query.search) : undefined;
            this.vehicleTypes = route.query.vehicleTypes ? String(route.query.vehicleTypes).split(",") : [];
            this.marks = route.query.marks ? String(route.query.marks).split(",") : [];
            this.models = route.query.models ? String(route.query.models).split(",") : [];
            this.divisions = route.query.divisions ? String(route.query.divisions).split(",") : [];
            this.technicalConditions = route.query.technicalConditions ? String(route.query.technicalConditions).split(",") : [];
            this.vehicleFreeTimeFrom = route.query.freeTimeFrom ? (route.query.freeTimeFrom as string) : undefined;
            this.vehicleFreeTimeTo = route.query.freeTimeTo ? (route.query.freeTimeTo as string) : undefined;
        } else if (storage) {
            const data = JSON.parse(storage);
            this.searchString = data.searchString ?? undefined;
            this.vehicleTypes = data.vehicleTypes ?? [];
            this.marks = data.marks ?? [];
            this.models = data.models ?? [];
            this.divisions = data.divisions ?? [];
            this.technicalConditions = data.technicalConditions ?? [];
            this.vehicleFreeTimeFrom = data.freeTimeFrom ?? undefined;
            this.vehicleFreeTimeTo = data.freeTimeTo ?? undefined;
        }

        super.map(route);
    }

    async persist(router: typeof Router) {
        const query = {
            ...super.createQuery(),
            search: this.searchString?.length ? this.searchString : undefined,
            vehicleTypes: this.vehicleTypes.length ? this.vehicleTypes : undefined,
            marks: this.marks.length ? this.marks : undefined,
            models: this.models.length ? this.models : undefined,
            divisions: this.divisions.length ? this.divisions : undefined,
            technicalConditions: this.technicalConditions.length ? this.technicalConditions : undefined,
            vehicleFreeTimeFrom: this.vehicleFreeTimeFrom?.length ? this.vehicleFreeTimeFrom : undefined,
            vehicleFreeTimeTo: this.vehicleFreeTimeTo?.length ? this.vehicleFreeTimeTo : undefined,
        };

        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(query));

        await router.replace({
            query: query,
        });
    }

    clearAllFilters() {
        this.searchString = undefined;
        this.vehicleTypes = [];
        this.marks = [];
        this.models = [];
        this.divisions = [];
        this.technicalConditions = [];
        this.vehicleFreeTimeFrom = undefined;
        this.vehicleFreeTimeTo = undefined;
        super.clearAllFilters();
    }

    applyOn(vehicles: Array<ReserveVehicleViewModel>): Array<ReserveVehicleViewModel> {
        return (super.applyOn(vehicles) as Array<ReserveVehicleViewModel>)
            .filter((vehicle) => filterVehicleType(this.vehicleTypes, vehicle))
            .filter((vehicle) => filterByMark(this.marks, vehicle))
            .filter((vehicle) => filterByModel(this.models, vehicle))
            .filter((vehicle) => filterByDivision(this.divisions, vehicle))
            .filter((vehicle) => filterByTechnicalCondition(this.technicalConditions, vehicle))
            .filter((vehicle) => filterBySearchString(this.searchString, vehicle))
            .filter((vehicle) => filterByVehicleFreeTime(this.vehicleFreeTimeFrom, this.vehicleFreeTimeTo, vehicle));

        function filterVehicleType(vehicleTypes: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!vehicleTypes.length) {
                return true;
            }
            return vehicleTypes.includes(vehicle?.type == null ? ReserveVehiclesListFilters.NO_VEHICLE : vehicle.type.label());
        }

        function filterByMark(marks: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!marks.length) {
                return true;
            }

            return hasValue(vehicle.mark) ? marks.includes(vehicle.mark) : marks.includes(ReserveVehiclesListFilters.NO_MARK);
        }

        function filterByModel(models: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!models.length) {
                return true;
            }

            return hasValue(vehicle.model) ? models.includes(vehicle.model) : models.includes(ReserveVehiclesListFilters.NO_MODEL);
        }

        function filterByDivision(divisions: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!divisions.length) {
                return true;
            }
            return vehicle.division == null ? divisions.includes(ReserveVehiclesListFilters.NO_DIVISION) : divisions.includes(vehicle.division);
        }

        function filterByTechnicalCondition(technicalConditions: string[], vehicle: ReserveVehicleViewModel): boolean {
            if (!technicalConditions.length) {
                return true;
            }
            return technicalConditions.includes(vehicle.repairStatus.technicalConditionLabel());
        }

        function filterBySearchString(searchString: string | undefined, vehicle: ReserveVehicleViewModel): boolean {
            if (!searchString?.length) {
                return true;
            }
            return (vehicle?.garageNumber?.toLowerCase().includes(searchString.toLowerCase()) || vehicle?.registrationNumber?.toLowerCase().includes(searchString.toLowerCase())) ?? false;
        }

        function filterByVehicleFreeTime(freeTimeFrom: string | undefined, freeTimeTo: string | undefined, vehicle: ReserveVehicleViewModel): boolean {
            if (!vehicle.jobs.length) {
                return true;
            }

            for (let i = 0; i < vehicle.jobs.length; i++) {
                const nextJob = i != vehicle.jobs.length - 1 ? vehicle.jobs[i + 1] : undefined;
                const previousJob = i != 0 ? vehicle.jobs[i - 1] : undefined;
                if (hasFreeTimeForJob(freeTimeFrom, freeTimeTo, vehicle.jobs[i], previousJob, nextJob)) {
                    return true;
                }
            }

            return false;

            function hasFreeTimeForJob(
                freeTimeFrom: string | undefined,
                freeTimeTo: string | undefined,
                job: api.ReserveJobInfoViewModel,
                previousJob: api.ReserveJobInfoViewModel | undefined,
                nextJob: api.ReserveJobInfoViewModel | undefined
            ): boolean {
                const jobStart = formatTime(job.runStart);
                const jobEnd = formatTime(job.runEnd);

                const isJobStartSameDay = moment(job.runStart).isSame(moment(), "day");
                const isJobEndSameDay = moment(job.runEnd).isSame(moment(), "day");

                const hasNextJob = nextJob != undefined;
                const hasPreviousJob = previousJob != undefined;

                const nextJobStart = hasNextJob ? formatTime(nextJob.runStart) : undefined;
                const previousJobEnd = hasPreviousJob ? formatTime(previousJob.runEnd) : undefined;

                const isNextJobStartNextDay = hasNextJob && moment(nextJob.runStart).isSame(moment().add(1, "day"), "day");
                const isPreviousJobEndPreviousDay = hasPreviousJob && moment(previousJob.runEnd).isSame(moment().subtract(1, "day"), "day");

                if (!hasPreviousJob && !hasNextJob && (hasFreeTimeBeforeJob(freeTimeFrom, freeTimeTo) || hasFreeTimeAfterJob(freeTimeFrom, freeTimeTo))) {
                    return true;
                }
                if (hasPreviousJob && !hasNextJob && hasFreeTimeAfterJob(freeTimeFrom, freeTimeTo)) {
                    return true;
                }
                if (!hasPreviousJob && hasNextJob && (hasFreeTimeBeforeJob(freeTimeFrom, freeTimeTo) || hasFreeTimeBetweenCurrentAndNextJob(freeTimeFrom, freeTimeTo))) {
                    return true;
                }
                if (hasPreviousJob && hasNextJob && (hasFreeTimeBetweenCurrentAndPreviousJob(freeTimeFrom, freeTimeTo) || hasFreeTimeBetweenCurrentAndNextJob(freeTimeFrom, freeTimeTo))) {
                    return true;
                }
                return false;

                function hasFreeTimeBeforeJob(freeTimeFrom: string | undefined, freeTimeTo: string | undefined): boolean {
                    return hasFreeTimeBeforeJob(freeTimeFrom) && hasFreeTimeBeforeJob(freeTimeTo);
                    function hasFreeTimeBeforeJob(freeTime: string | undefined): boolean {
                        return !freeTime || (isJobStartSameDay && jobStart >= freeTime);
                    }
                }
                function hasFreeTimeAfterJob(freeTimeFrom: string | undefined, freeTimeTo: string | undefined): boolean {
                    return hasFreeTimeAfterJob(freeTimeFrom) && hasFreeTimeAfterJob(freeTimeTo);
                    function hasFreeTimeAfterJob(freeTime: string | undefined): boolean {
                        return !freeTime || (isJobEndSameDay && jobEnd <= freeTime);
                    }
                }
                function hasFreeTimeBetweenCurrentAndPreviousJob(freeTimeFrom: string | undefined, freeTimeTo: string | undefined): boolean {
                    return hasFreeTimeBeforeJob(freeTimeFrom, freeTimeTo) && hasFreeTimeBetweenCurrentAndPreviousJob(freeTimeFrom) && hasFreeTimeBetweenCurrentAndPreviousJob(freeTimeTo);
                    function hasFreeTimeBetweenCurrentAndPreviousJob(freeTime: string | undefined): boolean {
                        return !freeTime || (hasPreviousJob && previousJobEnd! <= freeTime) || isPreviousJobEndPreviousDay;
                    }
                }
                function hasFreeTimeBetweenCurrentAndNextJob(freeTimeFrom: string | undefined, freeTimeTo: string | undefined): boolean {
                    return hasFreeTimeAfterJob(freeTimeFrom, freeTimeTo) && hasFreeTimeBetweenCurrentAndNextJob(freeTimeFrom) && hasFreeTimeBetweenCurrentAndNextJob(freeTimeTo);
                    function hasFreeTimeBetweenCurrentAndNextJob(freeTime: string | undefined): boolean {
                        return !freeTime || (hasNextJob && nextJobStart! >= freeTime) || isNextJobStartNextDay;
                    }
                }
            }
        }
    }
}
