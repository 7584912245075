import { api } from "@/api-client";
import { HealthInspectionListFilters } from "./HealthInspectionListFilters";
import moment, { FromTo } from "moment-timezone";
import { KeyOfType } from "@/helpers/Utils";

export type HealthInspectionMetricFiltersModelValue = {
    dateRange: FromTo;
    filters: HealthInspectionListFilters;
    timeUnit: api.MetricTimeUnit;
};

export const DEFAULT_FILTERS: HealthInspectionMetricFiltersModelValue = {
    dateRange: {
        from: moment().startOf("year"),
        to: moment(),
    },
    filters: new HealthInspectionListFilters(),
    timeUnit: api.MetricTimeUnit.Months,
};

type GraphFields = { key: KeyOfType<api.IHealthInspectionMetricsViewModel, number>; label: string };

export const WITHOUT_REMOVED_FIELDS: GraphFields[] = [
    { key: "automaticallyPositive", label: "Automaatselt läbitud" },
    { key: "manuallyPositive", label: "Käsitsi läbitud" },
    { key: "negative", label: "Mitteläbinud" },
];
