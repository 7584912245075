import { api } from "@/api-client";
import { ref } from "vue";

const memoizedDistances = ref<Map<string, number | undefined>>(new Map());

function getDistanceKey(startStopId: number, endStopId: number) {
    return `${startStopId}:${endStopId}`;
}

async function get(startStopId: number, endStopId: number) {
    const cacheKey = getDistanceKey(startStopId, endStopId);
    if (memoizedDistances.value.has(cacheKey)) {
        return memoizedDistances.value.get(cacheKey);
    }

    const client = new api.RedirectionsClient().ignoreNotFound();
    const distance = await client.distance(startStopId, endStopId).catch(() => undefined);
    if (distance == undefined) {
        return undefined;
    }

    memoizedDistances.value.set(cacheKey, distance.distanceInKm);
    return distance.distanceInKm;
}

export function useDistances() {
    return {
        get,
    };
}
