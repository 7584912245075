import { api } from "@/api-client";
import { RouteLocationNormalized } from "vue-router";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import vehicleCategoryTranslator from "@/features/VehicleCategoryTranslator";
import vehicleBodyTypes from "@/features/VehicleBodyTypes";
import moment, { FromTo } from "moment-timezone";

export abstract class JobOrderFilter {
    abstract label: string;
    abstract options: readonly string[];
    selectedOptions: string[] = [];

    removeOption(removedOption: string) {
        this.selectedOptions = this.selectedOptions.filter((x) => x !== removedOption);
    }

    clear() {
        this.selectedOptions = [];
    }
}

export class JobOrderVehicleTypeFilter extends JobOrderFilter {
    label = "Sõiduki liik";
    options: readonly string[] = [];

    constructor() {
        super();

        const categories = Object.values(api.VehicleCategory).filter((x): x is api.VehicleCategory => typeof x !== "string");

        const product = vehicleBodyTypes
            .all()
            .map((bodyType) => {
                return categories.map((category) => {
                    return new api.VehicleTypeViewModel({ category: category, type: bodyType });
                });
            })
            .flat();

        this.options = product.map((x) => x.label()).sort();
    }

    toInputModel() {
        // not good either, but limited by quasar api
        const selected = this.selectedOptions.map((vt) => (vt.includes("(") ? ([vt.split(" (")[0], vt.split(" (")[1].slice(0, -1)] as const) : ([vt, undefined] as const)));
        return selected.map(
            ([category, bodyType]) =>
                new api.JobOrderVehicleTypeFilterItem({
                    category: vehicleCategoryTranslator.stringToVehicleCategory(category),
                    bodyType: bodyType,
                })
        );
    }
}

export class JobOrderTypeFilter extends JobOrderFilter {
    label = "Korralduse liik";
    options = ["Muu sõidukorraldus", "Liini tihendamine"] as const;
    selectedOptions: (typeof this.options)[number][] = [];

    constructor() {
        super();
    }

    toInputModel(): api.JobOrderType[] {
        return this.selectedOptions.map((x: (typeof this.options)[number]) => {
            switch (x) {
                case "Muu sõidukorraldus":
                    return api.JobOrderType.JobOrder;
                case "Liini tihendamine":
                    return api.JobOrderType.LineInsertion;
                default:
                    throw new Error(`Unknown job order type ${x}`);
            }
        });
    }
}

export class WorkTypeFilter extends JobOrderFilter {
    label = "Tööliik";
    options: readonly string[] = [];

    constructor() {
        super();

        this.options = Object.values(workTypeTranslator.all)
            .filter((x) => x != null)
            .map((x) => workTypeTranslator.translate(x));
    }

    toInputModel() {
        // @ts-expect-error
        return this.selectedOptions.map((wt) => workTypeTranslator.all[wt] as api.WorkType);
    }
}

export class JobOrderDepartmentFilter extends JobOrderFilter {
    label = "Osakond";
    options: readonly string[] = [];

    static NoDepartmentOption = "Muud";

    constructor(departments: readonly string[]) {
        super();
        this.options = [...departments, JobOrderDepartmentFilter.NoDepartmentOption];
    }

    toInputModel() {
        return this.selectedOptions;
    }
}

export class JobOrderDriverGroupFilter extends JobOrderFilter {
    label = "Grupp";
    options: readonly string[] = [];
    static NoGroupOption = "-";

    constructor(driverGroups: readonly string[]) {
        super();
        this.options = [...driverGroups, JobOrderDriverGroupFilter.NoGroupOption];
    }

    toInputModel() {
        return this.selectedOptions;
    }
}

export type JobOrderFilterOptions = {
    jobOrderVehicleTypes: JobOrderVehicleTypeFilter;
    jobOrderTypes: JobOrderTypeFilter;
    jobOrderWorkTypes: WorkTypeFilter;
    jobOrderDepartments: JobOrderDepartmentFilter;
    jobOrderGroups: JobOrderDriverGroupFilter;
};

type JobOrderFilterSessionData = JobOrderFilterOptions & { searchString: string; day: FromTo | undefined };

export class JobOrderFilters {
    filters: JobOrderFilterOptions;
    searchString = "";
    day?: FromTo | string;

    static readonly STORAGE_KEY = "JobOrderFilters";

    constructor(departments: readonly string[], driverGroups: readonly string[]) {
        this.filters = {
            jobOrderVehicleTypes: new JobOrderVehicleTypeFilter(),
            jobOrderTypes: new JobOrderTypeFilter(),
            jobOrderWorkTypes: new WorkTypeFilter(),
            jobOrderDepartments: new JobOrderDepartmentFilter(departments),
            jobOrderGroups: new JobOrderDriverGroupFilter(driverGroups),
        };
    }

    updateDepartments(departments: readonly string[]) {
        this.filters.jobOrderDepartments.options = [...departments, JobOrderDepartmentFilter.NoDepartmentOption];
    }

    updateDriverGroups(driverGroups: readonly string[]) {
        this.filters.jobOrderGroups.options = [...driverGroups];
    }

    clone() {
        const clone = new JobOrderFilters(this.filters.jobOrderDepartments.options, this.filters.jobOrderGroups.options);
        clone.searchString = this.searchString;
        clone.filters = this.filters;
        clone.day = this.day;
        return clone;
    }

    mapFromRoute(route: RouteLocationNormalized) {
        const hasRoute = Object.keys(route.query).length > 0;
        const sessionStorageData = sessionStorage.getItem(JobOrderFilters.STORAGE_KEY);

        if (hasRoute) {
            this.getFilters().forEach((filter) => {
                filter.selectedOptions = route.query[filter.label] ? String(route.query[filter.label]).split(",") : filter.selectedOptions;
            });
            this.day = { from: route.query.runStartFrom as string, to: route.query.runStartTo as string };
            this.searchString = route.query.searchString as string;
        } else if (sessionStorageData != null) {
            const sessionData: JobOrderFilterSessionData = JSON.parse(sessionStorageData);

            this.searchString = sessionData.searchString ?? "";
            this.day = sessionData.day;

            Object.entries(sessionData).forEach(([key, value]) => {
                const filterKey = key as keyof typeof this.filters;
                if (key in this.filters && this.filters[filterKey] != null) {
                    this.filters[filterKey]!.selectedOptions = (value as JobOrderFilter).selectedOptions;
                }
            });
        }
    }

    saveToSessionStorage() {
        const sessionData = { ...this.filters, searchString: this.searchString, day: this.day };
        sessionStorage.setItem(JobOrderFilters.STORAGE_KEY, JSON.stringify(sessionData));
    }

    createQuery() {
        const query: Record<string, string | string[]> = Object.fromEntries(
            this.getFilters()
                .filter((filter) => filter.selectedOptions.length > 0)
                .map((filter) => [filter.label, filter.selectedOptions])
        );
        // @ts-expect-error
        query.runStartFrom = this.getRunStartFilter()?.from;
        // @ts-expect-error
        query.runStartTo = this.getRunStartFilter()?.to;
        // @ts-expect-error
        query.searchString = this.searchString !== "" ? this.searchString : undefined;

        return query;
    }

    getFilters(): JobOrderFilter[] {
        return Object.values(this.filters);
    }

    getRunStartFilter() {
        return typeof this.day === "string" ? { from: this.day, to: this.day } : this.day;
    }

    clearFilters() {
        this.getFilters().forEach((filter) => filter.clear());
        this.searchString = "";
        this.day = undefined;
        return this;
    }
}
