import { api } from "@/api-client";
import OperationModalTabs, { OperationModalTab } from "@/features/incidents/incidentView/shared/OperationModalTabs";

export default class ReplacementDriverAssignmentTabs extends OperationModalTabs {
    static JOB_INDEX = 0;
    get JOB(): OperationModalTab {
        return this.getTab(ReplacementDriverAssignmentTabs.JOB_INDEX);
    }

    static DRIVER_INDEX = 1;
    get DRIVER(): OperationModalTab {
        return this.getTab(ReplacementDriverAssignmentTabs.DRIVER_INDEX);
    }

    static VEHICLE_INDEX = 2;
    get VEHICLE(): OperationModalTab {
        return this.getTab(ReplacementDriverAssignmentTabs.VEHICLE_INDEX);
    }

    static STARTING_POINT_INDEX = 3;
    get STARTING_POINT(): OperationModalTab {
        return this.getTab(ReplacementDriverAssignmentTabs.STARTING_POINT_INDEX);
    }

    static PREPARATION_START_INDEX = 4;
    get PREPARATION_START(): OperationModalTab {
        return this.getTab(ReplacementDriverAssignmentTabs.PREPARATION_START_INDEX);
    }

    static REDIRECTIONS_INDEX = 5;
    get REDIRECTIONS(): OperationModalTab {
        return this.getTab(ReplacementDriverAssignmentTabs.REDIRECTIONS_INDEX);
    }

    static OVERVIEW_INDEX = 6;
    get OVERVIEW(): OperationModalTab {
        return this.getTab(ReplacementDriverAssignmentTabs.OVERVIEW_INDEX);
    }

    constructor(tabIndexes: number[], currentTabIndex: number) {
        const jobTab = new OperationModalTab(ReplacementDriverAssignmentTabs.JOB_INDEX, (_: number, model: api.AssignReplacementDriverInputModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            if (model.jobId == null) {
                validationErrors.appendMessage("Töö on kohustuslik", "jobId");
            }

            return !validationErrors.hasMessages();
        });

        const driverTab = new OperationModalTab(
            ReplacementDriverAssignmentTabs.DRIVER_INDEX,
            (_: number, model: api.AssignReplacementDriverInputModel | api.UpdateReplacementDriverAssignmentOperationInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();
                if (model.driverId == null) {
                    validationErrors.appendMessage("Juht on kohustuslik", "driverId");
                }

                return !validationErrors.hasMessages();
            }
        );

        const vehicleTab = new OperationModalTab(
            ReplacementDriverAssignmentTabs.VEHICLE_INDEX,
            (_: number, model: api.AssignReplacementDriverInputModel | api.UpdateReplacementDriverAssignmentOperationInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();
                if (model.vehicleId == null) {
                    validationErrors.appendMessage("Sõiduk on kohustuslik", "vehicleId");
                }
                return !validationErrors.hasMessages();
            }
        );

        const startingPointTab = new OperationModalTab(
            ReplacementDriverAssignmentTabs.STARTING_POINT_INDEX,
            (_: number, model: api.AssignReplacementDriverInputModel | api.UpdateReplacementDriverAssignmentOperationInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();

                if (model.driverStartingPoint == null) {
                    validationErrors.appendMessage("Alguspunkt on kohustuslik", "driverStartingPoint");
                }

                model.driverStartingPoint.validate(validationErrors);
                return !validationErrors.hasMessages();
            }
        );

        const preparationStartTab = new OperationModalTab(ReplacementDriverAssignmentTabs.PREPARATION_START_INDEX, () => true);

        const redirectionsTab = new OperationModalTab(
            ReplacementDriverAssignmentTabs.REDIRECTIONS_INDEX,
            async (incidentId: number, model: api.AssignReplacementDriverInputModel | api.UpdateReplacementDriverAssignmentOperationInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();
                await api.IncidentOperationRedirectionInputModel.validate(incidentId, model.redirections, validationErrors);
                return !validationErrors.hasMessages();
            }
        );

        const overviewTab = new OperationModalTab(ReplacementDriverAssignmentTabs.OVERVIEW_INDEX, () => true);

        super(
            [jobTab, driverTab, vehicleTab, startingPointTab, preparationStartTab, redirectionsTab, overviewTab].filter((x) => tabIndexes.some((i) => i == x.index)),
            currentTabIndex
        );
    }
}
