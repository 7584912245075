/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import settings from "./settings";
import * as generated from "./api-client";
import Loader, { type ILoader } from "@/components/Loader";
import vehicleCategoryTranslator from "@/features/VehicleCategoryTranslator";
import moment from "moment-timezone";
import { forEach } from "lodash";
import { getKilometersMismatchWarningMessage } from "@/shared/redirectionHelpers";

export namespace factory {
    /**
     * Creates a shallow copy of the object. NB! Does not create new instances for reference types.
     */
    export function createShallow<T>(type: { new (): T }, data: any = {}): T {
        const obj = new type() as any;
        obj.init({});
        Object.keys(data).forEach((key) => {
            obj[key] = data[key];
        });
        return obj;
    }

    /**
     * Creates a deep copy of the object, including new instances for reference types.
     */
    export function createDeep<T>(type: { new (): T }, data: any = {}): T {
        const obj = new type() as any;

        // Assuming that the type has an init method, call it with an empty object.
        if (typeof obj.init === "function") {
            obj.init({});
        }

        Object.keys(data).forEach((key) => {
            const value = data[key];

            // Handle dates separately
            if (value instanceof Date) {
                obj[key] = new Date(value.getTime());
            } else if (isReferenceType(value)) {
                // If the value is an array, deep clone the array recursively
                if (Array.isArray(value)) {
                    obj[key] = value.map((item) => (isReferenceType(item) ? createDeep((item as any).constructor, item) : item));
                } else {
                    // If it's an object, recursively create a new instance of its type
                    obj[key] = createDeep((value as any).constructor, value);
                }
            } else {
                // If it's a primitive value, assign it directly
                obj[key] = value;
            }
        });

        return obj;

        function isReferenceType(value: any): boolean {
            return value && (typeof value === "object" || Array.isArray(value));
        }
    }
}

export class ClientBase {
    private validationResult: ResultError | null = null;
    private loader: ILoader = new Loader();
    private static loadingClients: ClientBase[] = [];
    private handleNotFound = true;

    setLoader(loader: ILoader) {
        this.loader = loader;
        return this;
    }

    static areAnyClientsLoading() {
        return !!ClientBase.loadingClients.length;
    }

    getBaseUrl(defaultUrl: string, baseUrl?: string) {
        return settings.apiBaseUrl ?? defaultUrl;
    }

    protected transformOptions(options: RequestInit): Promise<RequestInit> {
        ClientBase.loadingClients.push(this);
        this.loader.startLoading();
        if (this.validationResult) {
            this.validationResult.clear();
        }

        // enables cookies
        options.credentials = "include";

        const headers = new Headers(options.headers);

        if (sessionStorage.getItem("tlt-documents-auth-token-type") != null && sessionStorage.getItem("tlt-documents-auth-token") != null) {
            headers.set("Authorization", `${sessionStorage.getItem("tlt-documents-auth-token-type")} ${sessionStorage.getItem("tlt-documents-auth-token")}`);
        }

        options.headers = headers;
        try {
            return Promise.resolve(options);
        } catch (e) {
            throw e;
        }
    }

    protected transformResult(url: string, response: Response, handleResponse: (response: Response) => any) {
        setTimeout(() => {
            // Timeout so that loading is cleared in next tick
            ClientBase.loadingClients.splice(ClientBase.loadingClients.indexOf(this), 1);
        }, 100);
        this.loader.endLoading();

        if (response.status == 404) {
            if (!this.handleNotFound) {
                return Promise.reject(response);
            }
        }

        return handleResponse(response)
            .then((r: any) => {
                return r;
            })
            .catch((e: any) => {
                if (this.validationResult && e instanceof ResultError) {
                    this.validationResult.clear();
                    this.validationResult.setMessages(e.messages);
                    (e as any).isHandled = true;
                }

                return Promise.reject(e);
            });
    }

    ignoreNotFound() {
        this.handleNotFound = false;
        return this;
    }

    setValidationResult(result: ResultError) {
        this.validationResult = result;
        return this;
    }

    setHeader() {}
}

// NOTE: When you want to extend classes, add the extended class name to api-client.nswag "extendedClasses" property.
export class ResultError extends generated.api.ResultError {
    handledMessages = [] as string[];

    onValidation = (): void => {};

    get isEmpty() {
        return !this.hasMessages();
    }

    hasMessages(key: string | null = null): boolean {
        return key ? Object.keys(this.messages).includes(key) : Object.keys(this.messages).length > 0;
    }

    get messagesFlattened() {
        return Object.values(this.messages).flat();
    }

    appendMessage(message: string, key = "") {
        if (this.messages![key]) {
            this.messages![key].push(message); // Vue's reactivity works with Array.push.
        } else {
            this.messages = { ...this.messages, [key]: [message] }; // Vue's reactivity does not work when adding new key to an object, so we trigger reactivity by replacing whole object.
        }
        return ResultError.fromJS(this);
    }

    handleMessage(key: string) {
        this.handledMessages.push(key);
    }

    setMessages(messages: { [key: string]: string[] }) {
        this.messages = messages;
        if (Object.keys(messages).length) {
            this.onValidation();
        }
    }

    onValidationOccurred(onValidation: () => void) {
        this.onValidation = onValidation;
    }

    clear() {
        this.messages = {};
        this.handledMessages.splice(0);
    }

    static createEmpty() {
        return factory.createShallow(ResultError);
    }
}

export class VehicleRepairStatusViewModel extends generated.api.VehicleRepairStatusViewModel {
    technicalConditionLabel() {
        return this.technicalCondition;
    }
}

export class RedirectionViewModel extends generated.api.RedirectionViewModel {
    toRedirectionInputModel(): generated.api.IncidentOperationRedirectionInputModel {
        const inputModel: generated.api.IIncidentOperationRedirectionInputModel = {
            jobId: this.jobId,
            vehicleId: this.vehicle?.id ?? 0,
            driverId: this.driver?.driverId ?? 0,
            startTime: this.departureTime,
            endTime: this.arrivalTime,
            startStopId: this.startStop?.id ?? 0,
            endStopId: this.endStop?.id ?? 0,
            distanceInKm: this.distanceInKm,
            withPassengers: this.withPassengers,
        };

        return factory.createShallow(generated.api.IncidentOperationRedirectionInputModel, inputModel);
    }
}

export class AssignReserveToJobInputModel extends generated.api.AssignReserveToJobInputModel {
    validateJob(resultError: ResultError) {
        if (this.jobId == null) {
            resultError.appendMessage("Töö on kohustuslik", "jobId");
        }
    }

    static createEmpty() {
        return factory.createShallow(AssignReserveToJobInputModel);
    }
}

export class RemoveDriverFromJobInputModel extends generated.api.RemoveDriverFromJobInputModel {
    validateIsDriverContinuingLater(resultError: ResultError) {
        if (this.isDriverContinuingLater == null) {
            resultError.appendMessage("Juhi jätkamise valik on kohustuslik", "isDriverContinuingLater");
        }
    }

    static createEmpty() {
        return factory.createShallow(AssignReserveToJobInputModel);
    }
}

export class OperationLocationInputModel extends generated.api.OperationLocationInputModel {
    isDeparture() {
        return this instanceof generated.api.DepartureOperationLocationInputModel;
    }

    validate(resultError: ResultError) {
        if (this.isDeparture()) {
            if ((this as generated.api.IOperationLocationInputModel as generated.api.IDepartureOperationLocationInputModel).departureId == null) {
                resultError.appendMessage("Väljumine on kohustuslik", "departureId");
            }
        } else {
            if ((this as generated.api.IOperationLocationViewModel as generated.api.ManuallyAddedOperationLocationInputModel).time == null) {
                resultError.appendMessage("Aeg on kohustuslik.", "time");
            }
            if ((this as generated.api.IOperationLocationViewModel as generated.api.ManuallyAddedOperationLocationInputModel).km == null) {
                resultError.appendMessage("Kilomeetrid on kohustuslikud.", "km");
            }
        }
    }
}

export class JobWithDriverAndVehicleInputModel extends generated.api.JobWithDriverAndVehicleInputModel {
    validate(resultError: ResultError) {
        if (this.jobId == null) {
            resultError.appendMessage("Töö on kohustuslik", "jobId");
        }
        if (this.driverId == null) {
            resultError.appendMessage("Juht on kohustuslik", "driverId");
        }
    }
}

export class JobWithDriverInputModel extends generated.api.JobWithDriverInputModel {
    validate(resultError: ResultError) {
        if (this.jobId == null) {
            resultError.appendMessage("Töö on kohustuslik", "jobId");
        }
        if (this.driverId == null) {
            resultError.appendMessage("Juht on kohustuslik", "driverId");
        }
    }
}

export class VehicleInputModel extends generated.api.VehicleInputModel {
    validate(resultError: ResultError) {
        if (this.id == null) {
            resultError.appendMessage("Juht on kohustuslik", "driverId");
        }
    }
}

export class IncidentOperationRedirectionInputModel extends generated.api.IncidentOperationRedirectionInputModel {
    get kilometerMismatchWarningMessage(): string | null {
        return getKilometersMismatchWarningMessage(this.distanceInKm, this.startTime, this.endTime);
    }

    static async validate(incidentId: number, redirections: generated.api.IncidentOperationRedirectionInputModel[], resultError: ResultError) {
        await new generated.api.IncidentOperationsClient()
            .setValidationResult(resultError)
            .validateRedirections(incidentId.toString(), redirections)
            .catch((_) => {}); // Currently, we need to use catch here to not throw the error
    }
}

export class OperationLocationViewModel extends generated.api.OperationLocationViewModel {
    isDeparture(): this is generated.api.DepartureAsOperationLocationViewModel {
        return this instanceof generated.api.DepartureAsOperationLocationViewModel;
    }

    getAddress() {
        const address = this instanceof generated.api.DepartureAsOperationLocationViewModel ? this.stop?.name : (this as generated.api.IManuallyAddedOperationLocationViewModel).address;

        return address ?? undefined;
    }

    getTime() {
        return this instanceof generated.api.DepartureAsOperationLocationViewModel ? this.arrivalAtMinutes : (this as generated.api.ManuallyAddedOperationLocationViewModel).time?.toString();
    }

    getKm() {
        return this instanceof generated.api.DepartureAsOperationLocationViewModel ? undefined : (this as generated.api.ManuallyAddedOperationLocationViewModel).km;
    }

    toInputModel(): generated.api.DepartureOperationLocationInputModel | generated.api.ManuallyAddedOperationLocationInputModel {
        return this.isDeparture()
            ? generated.api.DepartureOperationLocationInputModel.fromJS({ departureId: this.departureId })
            : generated.api.ManuallyAddedOperationLocationInputModel.fromJS({
                  address: (this as generated.api.ManuallyAddedOperationLocationViewModel).address,
                  time: (this as generated.api.ManuallyAddedOperationLocationViewModel).time,
                  km: (this as generated.api.ManuallyAddedOperationLocationViewModel).km,
              });
    }
}

export class VehicleTypeViewModel extends generated.api.VehicleTypeViewModel {
    label() {
        return `${vehicleCategoryTranslator.translate(this.category)} ${this.type != null ? `(${this.type})` : ``}`;
    }
}

export class IncidentJobModel extends generated.api.IncidentJobModel {
    isRecent() {
        return moment(this.day).isSame(moment(), "day") || (moment().hour() < 7 && moment(this.day).add(1, "day").isSame(moment(), "day"));
    }
}

export class ParametrizedError extends generated.api.ParametrizedError {
    get isParametrizedError() {
        return this.discriminator == "ParametrizedError";
    }

    get isGenericError() {
        return this.discriminator == "GenericError";
    }
}

export class LineScheduleDocumentViewModel extends generated.api.LineScheduleDocumentViewModel {
    get breakTimeLabel(): string {
        return this.breakTimeInMinutes > 120 ? "Vaheaeg" : "Lõuna";
    }
}

export class DepartureViewModel extends generated.api.DepartureViewModel {
    get canAddManualDepartureToJob() {
        return (
            (this.healthInspection?.hasPositiveTestResult ?? false) &&
            this.stopHasGate &&
            this.servingDriverAndVehicle.vehicle != null &&
            moment(this.plannedDeparture) < moment() &&
            moment(this.runTimeEndsAt) > moment()
        );
    }
}
