import { ListFilters } from "@/components/ListFilters";
import { api } from "@/api-client";
import workTypeTranslator from "@/features/WorkTypeTranslator";
import { ReserveDeparturesListFilters } from "@/features/preparation/departures/reserves/ReserveDeparturesListFilters";
import { getJobWithReadOnlyTags } from "../../shared/utils";

type FilterItem = api.IJobListItem | ReturnType<typeof getJobWithReadOnlyTags>;

export interface IIncidentJobSelectionTableFilters {
    searchString: string;
    workTypes: string[];
    selectedLineNumbers: string[];
    selectedVehicleGarageNumbers: string[];
}

export class IncidentJobSelectionTableFilters extends ListFilters implements IIncidentJobSelectionTableFilters {
    searchString = "";

    vehicleTypes: string[] = [];
    workTypes: string[] = [];
    selectedLineNumbers: string[];
    selectedVehicleGarageNumbers: string[];

    static NoVehicleType = "Sõidukiliik puudub";

    constructor(data?: IIncidentJobSelectionTableFilters) {
        super();
        this.searchString = data?.searchString ?? "";
        this.workTypes = data?.workTypes ?? [];
        this.selectedLineNumbers = data?.selectedLineNumbers ?? [];
        this.selectedVehicleGarageNumbers = data?.selectedVehicleGarageNumbers ?? [];
    }

    clearAllFilters(clearSearch = true): IncidentJobSelectionTableFilters {
        this.vehicleTypes = [];
        this.workTypes = [];
        this.selectedLineNumbers = [];
        this.selectedVehicleGarageNumbers = [];

        if (clearSearch) {
            this.searchString = "";
        }
        return this;
    }

    applyOn<T extends FilterItem>(jobs: T[]): T[] {
        jobs = this.filterBySearchString(jobs);
        jobs = this.filterByWorkType(jobs);
        jobs = this.filterByVehicleType(jobs);
        jobs = this.filterByLineNumber(jobs);
        jobs = this.filterByVehicleGarageNumber(jobs);
        return jobs;
    }

    filterBySearchString<T extends FilterItem>(jobs: T[]): T[] {
        return jobs.filter(
            (job) =>
                this.searchString.trim() === "" ||
                job.lineNumbers.some((lineNumber) => lineNumber.toLowerCase() === this.searchString.toLowerCase()) ||
                job.servingDriversAndVehicles.some(
                    (x) => x.driver.name.toLowerCase().startsWith(this.searchString.toLowerCase()) || x.driver.tableNumber.toLowerCase().startsWith(this.searchString.toLowerCase())
                ) ||
                job.servingDriversAndVehicles.some((x) => x.vehicle?.garageNumber?.toLowerCase().startsWith(this.searchString.toLowerCase()))
        );
    }

    filterByWorkType<T extends FilterItem>(jobs: T[]): T[] {
        return jobs.filter((job) => this.workTypes.length === 0 || this.workTypes.map((x) => workTypeTranslator.all[x as keyof typeof workTypeTranslator.all]).includes(job.workType));
    }

    filterByVehicleType<T extends FilterItem>(jobs: T[]): T[] {
        return jobs.filter(
            (job) =>
                this.vehicleTypes.length === 0 ||
                this.vehicleTypes.some((filter) =>
                    job.servingDriversAndVehicles.some((x) => x.vehicle?.type.label() == filter || (filter == ReserveDeparturesListFilters.NO_VEHICLE && x.vehicle == null))
                )
        );
    }

    filterByLineNumber<T extends FilterItem>(jobs: T[]): T[] {
        return jobs.filter((job) => this.selectedLineNumbers.length === 0 || this.selectedLineNumbers.some((sln) => job.lineNumbers.some((ln) => ln == sln)));
    }

    filterByVehicleGarageNumber<T extends FilterItem>(jobs: T[]): T[] {
        return jobs.filter(
            (job) => this.selectedVehicleGarageNumbers.length === 0 || this.selectedVehicleGarageNumbers.some((filter) => job.servingDriversAndVehicles.some((x) => x.vehicle?.garageNumber == filter))
        );
    }
}
