import hubController from "@/components/signalR/HubController";
import { App, ref, Plugin } from "vue";
import userInfo from "@/components/UserInfo";
import { IAppPlugin } from "@/plugins/AppPlugins";
import { ExpiringReroutesHub, OnExpiringReroutesNotification } from "@/features/shared/signalR/ExpiringReroutesHub";

export class ExpiringReroutesNotifier implements IAppPlugin {
    private _hubHandler: ExpiringReroutesHub | null = null;
    private _onNotifyOfExpiringReroutes: OnExpiringReroutesNotification = (message) => {
        this.showNotification.value = message.hasExpiringReroutes;
    };

    private showNotification = ref<boolean>(false);

    install = (app: App, options: unknown) => {
        if (userInfo.isAuthenticated && userInfo.canConnectToExpiringReroutesHub) {
            hubController.startExpiringReroutesHub(this._onNotifyOfExpiringReroutes).then((handler) => (this._hubHandler = handler));
        }

        app.provide(ExpiringReroutesNotifier.MESSAGE_KEY, this.showNotification);
        app.provide(ExpiringReroutesNotifier.INSTANCE_KEY, this);
    };

    public reloadIfNeeded = async () => {
        if (this._hubHandler == null || this._hubHandler.isClosed()) {
            await hubController.startExpiringReroutesHub(this._onNotifyOfExpiringReroutes);
        }
    };

    public static readonly MESSAGE_KEY: string = "EXPIRING_REROUTES_NOTIFIER_MESSAGE_KEY";
    public static readonly INSTANCE_KEY: string = "EXPIRING_REROUTES_NOTIFIER_KEY";
}

export default new ExpiringReroutesNotifier().install as Plugin;
