import { api } from "@/api-client";

class HealthInspectionResultTranslator {
    all() {
        return [
            { description: "Korras", value: api.HealthInspectionResult.Passed },
            { description: "Jääknähud - ootel", value: api.HealthInspectionResult.PendingWithResiduals },
            { description: "Korduvpuhumine - jääknähud", value: api.HealthInspectionResult.RepeatedTesting },
            { description: "Joove - töölt kõrvaldatud", value: api.HealthInspectionResult.Intoxicated },
            { description: "Korras - Tehniline rike", value: api.HealthInspectionResult.PassedWithTechnicalMalfunction },
            { description: "Korras - käsitsi", value: api.HealthInspectionResult.PassedManually },
            { description: "Muu tervisliku seisundi häire/muutus töölt kõrvaldamisega", value: api.HealthInspectionResult.RemovalFromWork },
            { description: 'Muu tervisliku seisundi häire/muutus "ootel"', value: api.HealthInspectionResult.PendingWithOtherHealthDisorder },
            { description: "Terviserike töökohustuste täitmisel", value: api.HealthInspectionResult.HealthDisorderOnDuty },
        ];
    }

    translate(value?: api.HealthInspectionResult) {
        return this.all().find((x) => x.value == value)?.description ?? "Puudub";
    }
}

const healthInspectionResultTranslator = new HealthInspectionResultTranslator();
export default healthInspectionResultTranslator;
