import { ExternalServiceInterruptionsNotifier } from "@/plugins/ExternalServiceInterruptionsNotifier";
import { PubliclyInaccessibleVehiclesNotifier } from "@/plugins/PubliclyInaccessibleVehiclesNotifier";
import { Plugin } from "vue";
import { ExpiredUpdatesNotifier } from "@/plugins/ExpiredUpdatesNotifier";
import { ExpiringReroutesNotifier } from "@/plugins/ExpiringReroutesNotifier";

export interface IAppPlugin {
    install: (app: any, options: unknown) => void;
}

// Wrapper for adding all custom plugins at once.
export class AppPlugins {
    private plugins: IAppPlugin[] = [new ExternalServiceInterruptionsNotifier(), new ExpiredUpdatesNotifier(), new PubliclyInaccessibleVehiclesNotifier(), new ExpiringReroutesNotifier()];

    public install = (app: any, options: unknown) => {
        this.plugins.forEach((plugin) => plugin.install(app, options));
    };
}

export default new AppPlugins().install as Plugin;
