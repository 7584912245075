import { api } from "@/api-client";
import OperationModalTabs, { OperationModalTab } from "@/features/incidents/incidentView/shared/OperationModalTabs";

export default class TramToTowAssignmentTabs extends OperationModalTabs {
    static TOWED_JOB_INDEX = 0;
    get TOWED_JOB(): OperationModalTab {
        return this.getTab(TramToTowAssignmentTabs.TOWED_JOB_INDEX);
    }

    static TOWER_JOB_INDEX = 1;
    get TOWER_JOB(): OperationModalTab {
        return this.getTab(TramToTowAssignmentTabs.TOWER_JOB_INDEX);
    }

    static TOWED_STOPPING_POINT_INDEX = 2;
    get TOWED_STOPPING_POINT(): OperationModalTab {
        return this.getTab(TramToTowAssignmentTabs.TOWED_STOPPING_POINT_INDEX);
    }

    static TOWER_STOPPING_POINT_INDEX = 3;
    get TOWER_STOPPING_POINT(): OperationModalTab {
        return this.getTab(TramToTowAssignmentTabs.TOWER_STOPPING_POINT_INDEX);
    }

    static TOWED_REDIRECTIONS_INDEX = 4;
    get TOWED_REDIRECTIONS(): OperationModalTab {
        return this.getTab(TramToTowAssignmentTabs.TOWED_REDIRECTIONS_INDEX);
    }

    static TOWER_REDIRECTIONS_INDEX = 5;
    get TOWER_REDIRECTIONS(): OperationModalTab {
        return this.getTab(TramToTowAssignmentTabs.TOWER_REDIRECTIONS_INDEX);
    }

    static OVERVIEW_INDEX = 6;
    get OVERVIEW(): OperationModalTab {
        return this.getTab(TramToTowAssignmentTabs.OVERVIEW_INDEX);
    }

    constructor(tabIndexes: number[], currentTabIndex: number) {
        const towedJobTab = new OperationModalTab(TramToTowAssignmentTabs.TOWED_JOB_INDEX, (_: number, model: api.AssignTramToTowInputModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            if (model.towedJobId == null) {
                validationErrors.appendMessage("Töö on kohustuslik", "jobId");
            }
            if (model.towedDriverAndVehicleId == null) {
                validationErrors.appendMessage("Juht ja sõiduk on kohustuslikud", "driverAndVehicleId");
            }

            return !validationErrors.hasMessages();
        });

        const towerJobTab = new OperationModalTab(TramToTowAssignmentTabs.TOWER_JOB_INDEX, (_: number, model: api.AssignTramToTowInputModel, validationErrors: api.ResultError) => {
            validationErrors.clear();

            if (model.towerJobId == null) {
                validationErrors.appendMessage("Töö on kohustuslik", "jobId");
            }

            if (model.towerDriverAndVehicleId == null) {
                validationErrors.appendMessage("Juht ja sõiduk on kohustuslikud", "driverAndVehicleId");
            }

            return !validationErrors.hasMessages();
        });

        const towedStoppingPointTab = new OperationModalTab(
            TramToTowAssignmentTabs.TOWED_STOPPING_POINT_INDEX,
            (_: number, model: api.AssignTramToTowInputModel | api.UpdateTramToTowOperationInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();
                if (model.towedStoppingPoint == null) {
                    return false;
                }

                model.towedStoppingPoint.validate(validationErrors);
                return !validationErrors.hasMessages();
            }
        );

        const towerStoppingPointTab = new OperationModalTab(
            TramToTowAssignmentTabs.TOWER_STOPPING_POINT_INDEX,
            (_: number, model: api.AssignTramToTowInputModel | api.UpdateTramToTowOperationInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();

                if (model.towerStoppingPoint == null) {
                    return false;
                }

                model.towerStoppingPoint.validate(validationErrors);
                return !validationErrors.hasMessages();
            }
        );

        const towedRedirectionsTab = new OperationModalTab(
            TramToTowAssignmentTabs.TOWED_REDIRECTIONS_INDEX,
            async (incidentId: number, model: api.AssignTramToTowInputModel | api.UpdateTramToTowOperationInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();
                await api.IncidentOperationRedirectionInputModel.validate(incidentId, model.towedRedirections, validationErrors);

                if (model.towedRedirections.length < 1) {
                    validationErrors.appendMessage("Vähemalt üks ülesõit on kohustuslik", "towedRedirections");
                }

                return !validationErrors.hasMessages();
            }
        );

        const towerRedirectionsTab = new OperationModalTab(
            TramToTowAssignmentTabs.TOWER_REDIRECTIONS_INDEX,
            async (incidentId: number, model: api.AssignTramToTowInputModel | api.UpdateTramToTowOperationInputModel, validationErrors: api.ResultError) => {
                validationErrors.clear();
                await api.IncidentOperationRedirectionInputModel.validate(incidentId, model.towerRedirections, validationErrors);

                if (model.towerRedirections.length < 1) {
                    validationErrors.appendMessage("Vähemalt üks ülesõit on kohustuslik", "towerRedirections");
                }

                return !validationErrors.hasMessages();
            }
        );

        const overviewTab = new OperationModalTab(TramToTowAssignmentTabs.OVERVIEW_INDEX, () => true);

        super(
            [towedJobTab, towerJobTab, towedStoppingPointTab, towerStoppingPointTab, towedRedirectionsTab, towerRedirectionsTab, overviewTab].filter((x) => tabIndexes.some((i) => i == x.index)),
            currentTabIndex
        );
    }
}
