import { Ref, ref } from "vue";

/**
 * Ref value that lazy loads its initial value upon first access. Wrap in a reactive object for cleaner syntax.
 * @param initialize Function that returns a promise that resolves to the value. This function is only called once after the first access.
 * @param emptyValue Value to return before the promise resolves.
 *
 * @returns On first access, the initialiation function is called and the empty value is returned. Once the promise resolves, the initialized value will be returned in subsequent accesses.
 */
export default function useLazy<T>(initialize: () => Promise<T>, emptyValue: T) {
    const innerValue = ref(emptyValue) as Ref<T>; // cast necessary: https://github.com/vuejs/core/issues/2136
    const isLoading = ref(true); // init function is called only once, no matter what
    const loadingPromise = ref<Promise<T>>();

    return {
        /**
         * The value. First access will cause the value to be loaded.
         */
        get value() {
            load(); // begins loading process in the background if not already started
            return innerValue;
        },
        /**
         * Whether the value has been loaded. Useful for distinguishing between explicitly undefined & unloaded values.
         */
        isLoading,
    };

    function load() {
        if (!isLoading.value) return;

        if (loadingPromise.value == null) {
            loadingPromise.value = initialize();
        }

        loadingPromise.value.then((result) => {
            innerValue.value = result;
            isLoading.value = false;

            return result;
        });
    }
}
