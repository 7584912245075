import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";

export class NotifyOfExpiringReroutesHubMessage extends HubMessage<NotifyOfExpiringReroutesHubMessage> {
    hasExpiringReroutes = false;

    public override overrideFromJSON(data: any): NotifyOfExpiringReroutesHubMessage {
        const result = new NotifyOfExpiringReroutesHubMessage();
        result.hasExpiringReroutes = data["hasExpiringReroutes"];
        return result;
    }
}

export type OnExpiringReroutesNotification = (message: NotifyOfExpiringReroutesHubMessage) => void;

export class ExpiringReroutesHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("expiring-reroutes-hub");
    }

    public async open(onNotifyOfExpiringReroutes: OnExpiringReroutesNotification) {
        await super.onMessage(NotifyOfExpiringReroutesHubMessage, "NotifyOfExpiringReroutes", onNotifyOfExpiringReroutes);
        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }

    public async getCurrentState() {
        await super.send("OnRequestCurrentState");
    }
}
