import script from "./ViruTerminalArrivalTable.vue?vue&type=script&setup=true&lang=ts"
export * from "./ViruTerminalArrivalTable.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTd});
