import { HubConnectionProvider, HubMessage, IHub } from "@/components/signalR/HubConnectionProvider";
import { api } from "@/api-client";

type DeparturesUpsertedMessageType = { jobId: number; departures: api.DepartureViewModel[] };

export class DeparturesUpsertedMessage extends HubMessage<DeparturesUpsertedMessage> {
    jobId!: DeparturesUpsertedMessageType["jobId"];
    departures!: DeparturesUpsertedMessageType["departures"];

    public override overrideFromJSON(data: any): DeparturesUpsertedMessage {
        const result = new DeparturesUpsertedMessage();
        result.jobId = data["jobId"];
        result.departures = data["departures"].map((data: api.DepartureViewModel) => (data.isRegular ? api.RegularDepartureViewModel.fromJS(data) : api.ReserveDepartureViewModel.fromJS(data)));

        return result;
    }
}

export type OnDeparturesUpserted = (message: DeparturesUpsertedMessage) => void;

export class DeparturesDeletedMessage extends HubMessage<DeparturesDeletedMessage> {
    jobId!: number;

    public override overrideFromJSON(data: any): DeparturesDeletedMessage {
        const result = new DeparturesDeletedMessage();
        result.jobId = data["jobId"];

        return result;
    }
}

export type OnDeparturesDeleted = (message: DeparturesDeletedMessage) => void;

export class DeparturesHub extends HubConnectionProvider implements IHub {
    constructor() {
        super("departures");
    }

    public async open(onDeparturesUpserted: OnDeparturesUpserted, onDeparturesDeleted: OnDeparturesDeleted) {
        await super.onMessage(DeparturesUpsertedMessage, "departuresUpserted", onDeparturesUpserted);
        await super.onMessage(DeparturesDeletedMessage, "departuresDeleted", onDeparturesDeleted);
        await super.start();
        return this;
    }

    public async close() {
        await super.stop();
    }
}
